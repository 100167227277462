const setActiveNav = function () {

  $(".navbar-nav > li:nth-child(1) a").addClass("home");

  // Get Current Page ID
  var pageid = jQuery("meta[name=pageid]").attr("content");
  if (!pageid.length) return; // Get Element to Highlight
  var activeElement = jQuery("#" + pageid);

  //Second level and third level nav not in menu
  var parentpageid = jQuery("meta[name=parentpageid]").attr("content");
  var activeParentElement = jQuery("#" + parentpageid);
  if(
    (".nav-item #"+parentpageid).length &&
    !activeElement.length && 
    !activeParentElement.hasClass("home") 
    ) { 
    activeParentElement.closest( "li.nav-item" ).find("a.nav-link").addClass("active");
  }

  if (!activeElement.length) return;
  if (
    activeElement.hasClass("nav-item") ||
    activeElement.hasClass("nav-link")
  ) {
    // If it's a to parent make it active'
    activeElement.addClass("active");

  } else {

    // If is not, go up until a top parent or main-menu
    var cont = true;
    var parent = activeElement.parent();
    do {
      if (parent.hasClass("nav-item")) {
        parent.addClass("active");
        cont = false;
      }
      if (parent.hasClass("main-menu")) {
        cont = false;
      }
      parent = parent.parent();
    } while (cont);
  }





};

export default setActiveNav;
