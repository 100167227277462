const dropdown = function () {
  var $window = jQuery(window);
  window.toggleDropdown = function (id) {
    var el = document.getElementById(id);
    el.classList.toggle("menu-open");
  };

  $(document).click(function (event) {
    var $target = $(event.target);
    if (!$target.closest("#aboutUsDropdown").length) {
      $("#aboutUsDropdown").removeClass("menu-open");
    }
  });

  function closeMenu() {
    $("#aboutUsDropdown").removeClass("menu-open");
  }

  $window.on("scroll", closeMenu);
};

export default dropdown;
