const animateOnView = function () {
    /** ---------------------------- //
     *  @group viewport trigger script
     * for adding or removing classes from elements in view within viewport
     */
  
    var $animationElements = $(".animation-element");
    var $window = $(window);
  
    function checkIfInView() {
      var windowHeight = $window.height();
      var windowTopPosition = $window.scrollTop();
      var windowBottomPosition = windowTopPosition + windowHeight;
  
      $.each($animationElements, function () {
        var $element = $(this);
        var elementHeight = $element.outerHeight();
        var elementTopPosition = $element.offset().top;
        var elementBottomPosition = elementTopPosition + elementHeight;
  
        //check to see if this current container is within viewport
        if (
          elementBottomPosition >= windowTopPosition &&
          elementTopPosition <= windowBottomPosition
        ) {

          $element.addClass("in-view");
          $element.removeClass("animation-element");
          $animationElements = $(".animation-element");
        }
      });
    }
  
    checkIfInView();
    $window.on("scroll resize", checkIfInView);
 

  };

  export default animateOnView;
  