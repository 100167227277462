import slider from "./components/slider";
import scrollTop from "./components/scroll-top";
import dropdown from "./components/dropdown";
import animateOnView from "./components/animate-on-view";
import setActiveNav from "./components/active-nav";
import contact from "./components/contact";
import news from "./components/news";

$(document).ready(function ($) {
  dropdown();
  scrollTop();
  animateOnView();
  setActiveNav();
  contact();
  news();
  slider.init({
    intervalSpeed: 7000,
    currentItem: 0,
    buttons: false,
    dots: false,
  });

   //Leadership bio page
   if ($(".divider-main-container").hasClass("bio")) {
     $(".leadership-container").addClass("bio");
   }
    //Join us page
    if ($(".center-element").hasClass("join-us")) {
      $(".cards-container").addClass("join-us");
    }   
});


