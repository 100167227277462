const news = function () {
  //News detail page
  if ($(".article-detail-container").hasClass("news")) {
    $(".breadcrumb-container").addClass("news");
  }

  var tags = getParameterValue("tags");
  function getParameterValue(param) {
    var url = window.location.href
      .slice(window.location.href.indexOf("?") + 1)
      .split("&");
    for (var i = 0; i < url.length; i++) {
      var urlparam = url[i].split("=");
      if (urlparam[0] == param) {
        return urlparam[1];
      }
    }
  }

  if (window.location.href.indexOf("tags=") > -1) {
    $(".article-filter select").val(decodeURI(tags));
    $("#news_all a").each(function () {
      var page = $(this).attr("href");
      $(this).attr("href", page + "&tags=" + tags);
    });
  }

  $(".article-filter select").on("change", function () {
    window.location = "/news?tags=" + $(this).val() + "&pg=1";
    if (!$(this).val()) {
      window.location = "/news";
    }
  });
};

export default news;
