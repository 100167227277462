const slider = (function () {
  //const
  const elements = document.querySelectorAll(".hero-slider-container");
  const backgrounds = document.querySelectorAll(".img-container");
  const sliderButtons = document.querySelectorAll(".slider-button");

  // data
  const itemsInfo = {
    offset: 0,
    position: {
      current: 0,
      min: 0,
      max: elements.length - 1,
    },
    intervalSpeed: 5000,

    update: function (value) {
      this.position.current = value;
      this.offset = -value;
    },
    reset: function () {
      this.position.current = 0;
      this.offset = 0;
    },
  };
  let interval = null;

  function init(props) {
    // let {buttonsEnabled, dotsEnabled} = controlsInfo;
    let { position, offset } = itemsInfo;
    if (elements) {
      if (props && props.intervalSpeed) {
        itemsInfo.intervalSpeed = props.intervalSpeed;
      }
      if (props && props.currentItem) {
        if (
          parseInt(props.currentItem) >= position.min &&
          parseInt(props.currentItem) <= position.max
        ) {
          position.current = props.currentItem;
          offset = -props.currentItem;
        }
      }

      startInterval();
    }
  }

  function startInterval() {
    interval = setInterval(function () {
      if (itemsInfo.position.current < itemsInfo.position.max) {
        itemsInfo.update(itemsInfo.position.current + 1);
      } else {
        itemsInfo.reset();
      }
      setClass();
    }, itemsInfo.intervalSpeed);
  }

  function setClass() {
    if (elements) {
      elements.forEach((element, index) => {
        if (element) {
          index === itemsInfo.position.current
            ? element.classList.add("animate")
            : element.classList.remove("animate");
        }
      });
    }
    if (backgrounds) {
      backgrounds.forEach((background, index) => {
        if (background) {
          index === itemsInfo.position.current
            ? background.classList.add("animate")
            : background.classList.remove("animate");
        }
      });
    }
    if (sliderButtons) {
      sliderButtons.forEach((button, index) => {
        if (button) {
          index === itemsInfo.position.current
            ? button.classList.add("selected")
            : button.classList.remove("selected");
        }
      });
    }
  }

  window.setActiveSlide = (index) => {
    itemsInfo.update(index);
    setClass();
    clearInterval(interval);
    startInterval();
  };

  window.setManualSlide = (index) => {
    itemsInfo.update(index);
    setClass();
    clearInterval(interval);
  };


  return { init };
})();

export default slider;
