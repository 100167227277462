const contact = function () {
  //Contact form
  $(".form-input.required").each(function () {
    var validation = $(this).attr("value");
    if (validation === undefined) {
      $(this).addClass("field-required");
    }
  });

  if (
    $(".form-message").length > 0 ||
    $(".field-validation-error").length > 0
  ) {
    $("html, body").animate({
      scrollTop: $("#contact").offset().top,
    });
  }

  $("input.form-input.required").attr("aria-required", "true");
};

export default contact;
